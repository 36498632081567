// NOTE: this is here to include styles that are common for Tailwind styles and globals

@import 'mixins.less';
@import 'variables.less';

.logo {
	display: inline-block;
	.size(140px, 52px);
	background: url('./img/logo-iqvia.svg') no-repeat 0 center;
	background-size: auto 100%;
	vertical-align: top;
	text-indent: -9999px;
	opacity: 0.7;
	.transition(all 0.2s ease-in-out);
}

.sticky-message,
.info-message,
.warning-message {
	position: fixed;
	left: 0;
	bottom: 0;
	.size(100%, auto);
	.font-size(14, 1.5em);
	.transition(all 200ms ease-in-out);
	.translate(0, 100%);
	.transform-origin(bottom);
	z-index: 40;

	&.new-layout {
		padding-left: 64px;
	}

	&.visible {
		.translate(0, 0);
	}
	.message {
		position: relative;
		padding: 14px 20px;

		&.thankyou {
			color: #155724;
			background: #c3e6cb;
		}
		&.plea {
			color: #606060;
			background: #ffd300;
		}
		&.warning {
			color: #606060;
			background: #ffa1a6;
		}
		&.success {
			color: #155724;
			background: #c3e6cb;
		}
		&.error {
			color: #721c24;
			background: #f5c6cb;
		}
	}
	.message-content {
		margin-right: 40px;

		a {
			text-decoration: underline;
		}
	}
	.btn-message-close {
		display: block;
		position: absolute;
		right: 20px;
		top: 14px;
		.sq(20px);
		color: #155724;
		background: url('./img/ico-close-black.svg') center center no-repeat;
		background-size: 16px;
		text-indent: -9999px;
		.opacity(60);
		.transition(all 0.2s ease-in-out);

		&:hover {
			.opacity(100);
			.rotate(90deg);
		}
	}
}

// ---------
// LOGIN
// ---------
.reset-password,
.login {
	.page {
		height: 100%;
	}
	.page-header {
		position: absolute;
		left: 0;
		top: 0;
	}
	section {
		padding: 0;
		height: 100%;
	}
	.full-screen-form {
		padding: 80px 0;
	}
}
