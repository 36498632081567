/* ---------
CHARTS
--------- */
.highcharts-figure {
	@apply block w-full border border-gray-200 bg-white rounded overflow-hidden;
	box-shadow: var(--shadow-elevation-low);
}

.highcharts-credits {
	@apply hidden !important;
}

.highcharts-tooltip > span {
	display: block;
	/*padding:4px;
	background:#ffffff;*/
}
.highcharts-tooltip h2 {
	display: block !important;
	padding-bottom: 4px;
	margin-bottom: 4px;
	border-bottom: 1px solid #f3f4f6;
	font-family: 'Montserrat', 'system-ui', '-apple-system', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', 'sans-serif',
		'Apple Color Emoji', 'Segoe UI Emoji';
	font-weight: 700;
	font-size: 12px;
	line-height: 16px;
}
.highcharts-tooltip table th {
	padding-bottom: 0.2em;
	@apply font-semibold border-b border-gray-200;
}
.highcharts-tooltip table td {
	padding: 3px 0 0 0;
	@apply font-semibold;
}
.highcharts-tooltip table .td-right {
	padding: 3px 0 0 5px;
	@apply font-semibold tabular-nums text-right;
}

.highcharts-tooltip table.table-timeline-tooltip .tooltip-h2 {
	padding-bottom: 4px;
	border-bottom: 1px solid #e0e0e0;
	font-family: 'Montserrat', 'system-ui', '-apple-system', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', 'sans-serif',
		'Apple Color Emoji', 'Segoe UI Emoji';
	font-weight: 700;
	font-size: 12px;
	line-height: 16px;
}
.highcharts-tooltip .table-timeline-tooltip td {
	padding: 6px 4px 0 0;
	font-size: 11px;
	line-height: 12px;
	font-weight: normal;
}
.highcharts-tooltip .table-timeline-tooltip td.td-right {
	padding: 6px 0 0 4px;
	font-variant-numeric: tabular-nums;
	text-align: right;
}
.highcharts-tooltip .table-timeline-tooltip .name-point {
	display: inline-block;
	width: 8px;
	height: 8px;
	margin: 3px 4px 0 0;
	border-radius: 4px;
	vertical-align: top;
}
/*.highcharts-tooltip .table-timeline-tooltip .name-size-150,
.highcharts-tooltip .table-timeline-tooltip .name-size-250,*/
.highcharts-tooltip .table-timeline-tooltip .name-size-300 {
	display: inline-block;
	/*max-width: 150px;*/
	max-width: 300px;
	font-size: 11px;
	line-height: 13px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: top;
}
/* 
	ZP - DIS13 - remove max width 
	- search
	- group detail
*/
.medical-device-dis-detail .highcharts-tooltip .table-line-chart .name-size-300,
.medical-device-group-detail .highcharts-tooltip .table-line-chart .name-size-300,
.medical-device-dis-detail .highcharts-tooltip .table-stacked-chart .name-size-300,
.medical-device-group-detail .highcharts-tooltip .table-stacked-chart .name-size-300 {
	max-width:100%;
}
/*.highcharts-tooltip .table-timeline-tooltip .name-size-250 {
	max-width: 250px;
}
.highcharts-tooltip .table-timeline-tooltip .name-size-300 {
	max-width: 300px;
}*/

.highcharts-menu {
	@apply p-0 shadow-none border-cPurple-800 rounded !important;
}
.highcharts-menu-item {
	@apply px-2 py-1 text-label text-cPurple-800 font-display hover:bg-cPurple-500 hover:text-white !important;
}

.date-range-chart-legend {
	margin: 20px 0 0 0;
}

.date-range-chart-legend-content {
	position: relative;
	display: flex;
	flex-wrap: nowrap;
	width: 100%;
	height: 20px;
	white-space: nowrap;
	overflow: hidden;
}

.date-range-chart-legend-item {
	display: inline-block;
	border-left: 1px solid #e0e0e0;
	font-size: 12px;
	line-height: 20px;
	font-weight: 700;
	color: #666;
	text-align: center;
	vertical-align: top;
}

.date-range-chart {
	position: relative;
	width: 100%;
}

.date-range-chart-content {
	position: relative;
	padding: 1px 6px;

	border: 5px solid #eaeaea;
	border: 4px solid #e0e0e0;
	border-radius: 4px;

	background: #fff;
	overflow: hidden;
}

.date-range-chart-bar {
	position: relative;
	display: block;
	margin: 6px 0;
	padding: 6px 8px;
	min-height: 26px;
	border-radius: 4px;
	font-size: 12px;
	line-height: 1.3em;
	font-weight: 700;
	color: #111;
	background: #eaeaea;
	background: #e0e0e0;
	/*text-decoration: underline;*/
	text-decoration: none;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
.date-range-chart-bar:hover {
	color: #fff;
	background: #262955;
}
.date-range-chart-bar span {
	display: block;
	margin: 1px 0 0 0;
	max-width: 100%;
	font-size: 11px;
	font-weight: 500;
	line-height: 1.2em;
	opacity: 0.6;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.date-range-chart-slider {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
}

.date-range-chart-slider-content {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: none;
}

.date-range-chart-selected-range {
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border-radius: 4px;
	border-left: 4px solid #b2b6ff;
	border-right: 4px solid #b2b6ff;
	background: rgba(178, 182, 255, 0.25);
	pointer-events: none;
	z-index: 1;
}

.date-range-chart-handle {
	position: absolute;
	top: 50%;
	margin-left: -12px;
	margin-top: -15px;
	width: 14px;
	height: 30px;
	border-radius: 4px 0 0 4px;
	background: #b2b6ff;
	border: none;
	outline: 0;
	z-index: 2;
	cursor: col-resize;
}
.date-range-chart-handle:hover {
	background: #111;
}

.date-range-chart-handle:focus {
	outline: 0;
}

.date-range-chart-handle:last-child {
	margin-left: -2px;
	border-radius: 0 4px 4px 0;
}

.date-range-chart-handle:last-child:before {
	margin-left: -3px;
}
.date-range-chart-handle:last-child:after {
	margin-left: 1px;
}

.date-range-chart-handle:before,
.date-range-chart-handle:after {
	content: '';
	position: absolute;
	left: 50%;
	top: 50%;
	margin-top: -8px;
	height: 16px;
	width: 1px;
	background: #ffff;
}

.date-range-chart-handle:before {
	margin-left: -2px;
}
.date-range-chart-handle:after {
	margin-left: 2px;
}

.highcharts-menu {
	padding: 0 !important;
	box-shadow: 0 0 #0000 !important;
	border: 1px solid #262955 !important;
	border-radius: 4px !important;
}
.highcharts-menu-item {
	padding: 4px 8px;
	font-family: 'Roboto', 'system-ui', '-apple-system', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', 'sans-serif',
		'Apple Color Emoji', 'Segoe UI Emoji';
	font-size: 12px;
	color: #262955;
}
.highcharts-menu-item:hover {
	color: #ffffff;
	background: #5150a9;
}
.highcharts-exporting-group text {
	color: #ffffff !important;
	font-weight: normal !important;
	fill: #ffffff !important;
}

/* LEGEND - remove stroke on legends point  */
.highcharts-legend-item .highcharts-graph {
	display: none;
}
