// Mixins.less
// Snippets of reusable CSS to develop faster and keep code readable
// -----------------------------------------------------------------

// UTILITY MIXINS
// --------------------------------------------------

.truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

// Clearfix
// --------
// For clearing floats like a boss h5bp.com/q
.c {
	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

// New image replacement
// -------------------------
.ir(@width: 5px, @height: 5px, @display: block) {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
	display: @display;
	width: @width;
	height: @height;
}

// Sizing shortcuts
// -------------------------
.size(@width: 5px, @height: 5px) {
	width: @width;
	height: @height;
}

.square(@size: 5px) {
	.size(@size, @size);
}

.sq(@size: 5px) {
	.size(@size, @size);
}

// FONTS
// --------------------------------------------------

#font {
	#family {
		.serif() {
			font-family: Georgia, 'Times New Roman', Times, serif;
		}
		.sans-serif() {
			font-family: 'Roboto', 'Arial CE', Arial, sans-serif;
		}
	}

	//noinspection CssOverwrittenProperties
	.size(@font-size: 16, @line: 1) {
		@rem: (@font-size / 10);
		font-size: @font-size * 1px;
		font-size: ~'@{rem}rem';
		line-height: @line;
	}
}

//noinspection CssOverwrittenProperties
.font-size(@font-size: 16, @line: 1) {
	@rem: (@font-size / 16);
	font-size: @font-size * 1px;
	font-size: ~'@{rem}rem';
	line-height: @line;
}

// CSS3 PROPERTIES
// --------------------------------------------------

// ANIMATE
.animation(@name, @duration: 1s, @delay: 0, @ease: linear) {
	-webkit-animation: @name @duration @delay @ease;
	-moz-animation: @name @duration @delay @ease;
	-o-animation: @name @duration @delay @ease;
	-ms-animation: @name @duration @delay @ease;
	animation: @name @duration @delay @ease;
}

// Border Radius
.border-radius(@radius: 5px) {
	-webkit-border-radius: @radius;
	-moz-border-radius: @radius;
	border-radius: @radius;
}

// Drop shadows
.box-shadow(@shadow: 0 1px 3px rgba(0,0,0,0.25)) {
	-webkit-box-shadow: @shadow;
	-moz-box-shadow: @shadow;
	box-shadow: @shadow;
}

// Transitions
.transition(@transition) {
	-webkit-transition: @transition;
	-moz-transition: @transition;
	-ms-transition: @transition;
	-o-transition: @transition;
	transition: @transition;
}

.transition-easing(@easing) {
	-webkit-transition-timing-function: @easing;
	-moz-transition-timing-function: @easing;
	-ms-transition-timing-function: @easing;
	-o-transition-timing-function: @easing;
	transition-timing-function: @easing;
}

.transition-timing(@origin) {
	-webkit-transform-origin: @origin;
	-moz-transform-origin: @origin;
	-o-transform-origin: @origin;
	transform-origin: @origin;
}

// Transformations
.transform(@transformation) {
	-webkit-transform: @transformation;
	-moz-transform: @transformation;
	-ms-transform: @transformation;
	-o-transform: @transformation;
	transform: @transformation;
}

.rotate(@degrees) {
	-webkit-transform: rotate(@degrees);
	-moz-transform: rotate(@degrees);
	-ms-transform: rotate(@degrees);
	-o-transform: rotate(@degrees);
	transform: rotate(@degrees);
}

.scale(@ratio) {
	-webkit-transform: scale(@ratio);
	-moz-transform: scale(@ratio);
	-ms-transform: scale(@ratio);
	-o-transform: scale(@ratio);
	transform: scale(@ratio);
}

.translate(@x: 0, @y: 0) {
	-webkit-transform: translate(@x, @y);
	-moz-transform: translate(@x, @y);
	-ms-transform: translate(@x, @y);
	-o-transform: translate(@x, @y);
	transform: translate(@x, @y);
}

.skew(@x: 0, @y: 0) {
	-webkit-transform: translate(@x, @y);
	-moz-transform: translate(@x, @y);
	-ms-transform: translate(@x, @y);
	-o-transform: translate(@x, @y);
	transform: translate(@x, @y);
}

.skew(@x: 0, @y: 0) {
	-webkit-transform: skew(@x, @y);
	-moz-transform: skew(@x, @y);
	-ms-transform: skew(@x, @y);
	-o-transform: skew(@x, @y);
	transform: skew(@x, @y);
}

.transform-origin(@origin) {
	-webkit-transform-origin: @origin;
	-moz-transform-origin: @origin;
	-o-transform-origin: @origin;
	transform-origin: @origin;
}

// Background clipping
// Heads up: FF 3.6 and under need "padding" instead of "padding-box"
.background-clip(@clip) {
	-webkit-background-clip: @clip;
	-moz-background-clip: @clip;
	background-clip: @clip;
}

// Background sizing
.background-size(@size) {
	-webkit-background-size: @size;
	-moz-background-size: @size;
	-o-background-size: @size;
	background-size: @size;
}

// User select
// For selecting text on the page
.user-select(@select) {
	-webkit-user-select: @select;
	-moz-user-select: @select;
	-o-user-select: @select;
	user-select: @select;
}

// Resize anything
.resizable(@direction: both) {
	resize: @direction; // Options: horizontal, vertical, both
	overflow: auto; // Safari fix
}

// Opacity
.opacity(@opacity: 100) {
	opacity: @opacity / 100;
	filter: e(%('alpha(opacity=%d)', @opacity));
}

// BACKGROUNDS
// --------------------------------------------------

// Add an alpha-transparency value to any background or border color (via Elyse Holladay)
#translucent {
	.background(@color: @white, @alpha: 1) {
		background-color: hsla(hue(@color), saturation(@color), lightness(@color), @alpha);
	}
	.border(@color: @white, @alpha: 1) {
		border-color: hsla(hue(@color), saturation(@color), lightness(@color), @alpha);
		.background-clip(padding-box);
	}
}

// Gradient Bar Colors for buttons and alerts
//noinspection CssOverwrittenProperties
.gradientBar(@primaryColor, @secondaryColor) {
	#gradient > .vertical(@primaryColor, @secondaryColor);
	border-color: @secondaryColor @secondaryColor darken(@secondaryColor, 15%);
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) fadein(rgba(0, 0, 0, 0.1), 15%);
}

// Gradients
#gradient {
	.horizontal(@startColor: #555, @endColor: #333) {
		background-color: @endColor;
		background-image: -moz-linear-gradient(left, @startColor, @endColor); // FF 3.6+
		background-image: -ms-linear-gradient(left, @startColor, @endColor); // IE10
		background-image: -webkit-gradient(linear, 0 0, 100% 0, from(@startColor), to(@endColor)); // Safari 4+, Chrome 2+
		background-image: -webkit-linear-gradient(left, @startColor, @endColor); // Safari 5.1+, Chrome 10+
		background-image: -o-linear-gradient(left, @startColor, @endColor); // Opera 11.10
		background-image: linear-gradient(left, @startColor, @endColor); // Le standard
		background-repeat: repeat-x;
		filter: e(
			%(
				"progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=1)",
				@startColor,
				@endColor
			)
		); // IE9 and down
	}
	.vertical(@startColor: #555, @endColor: #333) {
		background-color: mix(@startColor, @endColor, 60%);
		background-image: -moz-linear-gradient(top, @startColor, @endColor); // FF 3.6+
		background-image: -ms-linear-gradient(top, @startColor, @endColor); // IE10
		background-image: -webkit-gradient(linear, 0 0, 0 100%, from(@startColor), to(@endColor)); // Safari 4+, Chrome 2+
		background-image: -webkit-linear-gradient(top, @startColor, @endColor); // Safari 5.1+, Chrome 10+
		background-image: -o-linear-gradient(top, @startColor, @endColor); // Opera 11.10
		background-image: linear-gradient(top, @startColor, @endColor); // The standard
		background-repeat: repeat-x;
		filter: e(
			%(
				"progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",
				@startColor,
				@endColor
			)
		); // IE9 and down
	}
	.directional(@startColor: #555, @endColor: #333, @deg: 45deg) {
		background-color: @endColor;
		background-repeat: repeat-x;
		background-image: -moz-linear-gradient(@deg, @startColor, @endColor); // FF 3.6+
		background-image: -ms-linear-gradient(@deg, @startColor, @endColor); // IE10
		background-image: -webkit-linear-gradient(@deg, @startColor, @endColor); // Safari 5.1+, Chrome 10+
		background-image: -o-linear-gradient(@deg, @startColor, @endColor); // Opera 11.10
		background-image: linear-gradient(@deg, @startColor, @endColor); // The standard
	}
	.vertical-three-colors(@startColor: #00b3ee, @midColor: #7a43b6, @colorStop: 50%, @endColor: #c3325f) {
		background-color: mix(@midColor, @endColor, 80%);
		background-image: -webkit-gradient(
			linear,
			0 0,
			0 100%,
			from(@startColor),
			color-stop(@colorStop, @midColor),
			to(@endColor)
		);
		background-image: -webkit-linear-gradient(@startColor, @midColor @colorStop, @endColor);
		background-image: -moz-linear-gradient(top, @startColor, @midColor @colorStop, @endColor);
		background-image: -ms-linear-gradient(@startColor, @midColor @colorStop, @endColor);
		background-image: -o-linear-gradient(@startColor, @midColor @colorStop, @endColor);
		background-image: linear-gradient(@startColor, @midColor @colorStop, @endColor);
		background-repeat: no-repeat;
		filter: e(
			%(
				"progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",
				@startColor,
				@endColor
			)
		); // IE9 and down, gets no color-stop at all for proper fallback
	}
	.radial(@innerColor: #555, @outerColor: #333) {
		background-color: @outerColor;
		background-image: -webkit-gradient(
			radial,
			center center,
			0,
			center center,
			460,
			from(@innerColor),
			to(@outerColor)
		);
		background-image: -webkit-radial-gradient(circle, @innerColor, @outerColor);
		background-image: -moz-radial-gradient(circle, @innerColor, @outerColor);
		background-image: -ms-radial-gradient(circle, @innerColor, @outerColor);
		background-repeat: no-repeat;
		// Opera cannot do radial gradients yet
	}
	.striped(@color, @angle: -45deg) {
		background-color: @color;
		background-image: -webkit-gradient(
			linear,
			0 100%,
			100% 0,
			color-stop(0.25, rgba(255, 255, 255, 0.15)),
			color-stop(0.25, transparent),
			color-stop(0.5, transparent),
			color-stop(0.5, rgba(255, 255, 255, 0.15)),
			color-stop(0.75, rgba(255, 255, 255, 0.15)),
			color-stop(0.75, transparent),
			to(transparent)
		);
		background-image: -webkit-linear-gradient(
			@angle,
			rgba(255, 255, 255, 0.15) 25%,
			transparent 25%,
			transparent 50%,
			rgba(255, 255, 255, 0.15) 50%,
			rgba(255, 255, 255, 0.15) 75%,
			transparent 75%,
			transparent
		);
		background-image: -moz-linear-gradient(
			@angle,
			rgba(255, 255, 255, 0.15) 25%,
			transparent 25%,
			transparent 50%,
			rgba(255, 255, 255, 0.15) 50%,
			rgba(255, 255, 255, 0.15) 75%,
			transparent 75%,
			transparent
		);
		background-image: -ms-linear-gradient(
			@angle,
			rgba(255, 255, 255, 0.15) 25%,
			transparent 25%,
			transparent 50%,
			rgba(255, 255, 255, 0.15) 50%,
			rgba(255, 255, 255, 0.15) 75%,
			transparent 75%,
			transparent
		);
		background-image: -o-linear-gradient(
			@angle,
			rgba(255, 255, 255, 0.15) 25%,
			transparent 25%,
			transparent 50%,
			rgba(255, 255, 255, 0.15) 50%,
			rgba(255, 255, 255, 0.15) 75%,
			transparent 75%,
			transparent
		);
		background-image: linear-gradient(
			@angle,
			rgba(255, 255, 255, 0.15) 25%,
			transparent 25%,
			transparent 50%,
			rgba(255, 255, 255, 0.15) 50%,
			rgba(255, 255, 255, 0.15) 75%,
			transparent 75%,
			transparent
		);
	}
}

// Reset filters for IE
.reset-filter() {
	filter: e(%('progid:DXImageTransform.Microsoft.gradient(enabled = false)'));
}

// Mixin for generating button backgrounds
// ---------------------------------------
.buttonBackground(@startColor, @endColor) {
	// gradientBar will set the background to a pleasing blend of these, to support IE<=9
	.gradientBar(@startColor, @endColor);
	.reset-filter();

	// in these cases the gradient won't cover the background, so we override
	&:hover,
	&:active,
	&.active,
	&.disabled,
	&[disabled] {
		background-color: @endColor;
	}

	// IE 7 + 8 can't handle box-shadow to show active, so we darken a bit ourselves
	&:active,
	&.active {
		background-color: darken(@endColor, 10%) e('\9');
	}
}

// COMPONENT MIXINS
// --------------------------------------------------

// POPOVER ARROWS
// -------------------------
// For tipsies and popovers
#popoverArrow {
	.top(@arrowWidth: 5px) {
		bottom: 0;
		left: 50%;
		margin-left: -@arrowWidth;
		border-left: @arrowWidth solid transparent;
		border-right: @arrowWidth solid transparent;
		border-top: @arrowWidth solid @black;
	}
	.left(@arrowWidth: 5px) {
		top: 50%;
		right: 0;
		margin-top: -@arrowWidth;
		border-top: @arrowWidth solid transparent;
		border-bottom: @arrowWidth solid transparent;
		border-left: @arrowWidth solid @black;
	}
	.bottom(@arrowWidth: 5px) {
		top: 0;
		left: 50%;
		margin-left: -@arrowWidth;
		border-left: @arrowWidth solid transparent;
		border-right: @arrowWidth solid transparent;
		border-bottom: @arrowWidth solid @black;
	}
	.right(@arrowWidth: 5px) {
		top: 50%;
		left: 0;
		margin-top: -@arrowWidth;
		border-top: @arrowWidth solid transparent;
		border-bottom: @arrowWidth solid transparent;
		border-right: @arrowWidth solid @black;
	}
}
