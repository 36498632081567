@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'charts.css';

/* v3 fix */
.shrink-0 {
	@apply flex-shrink-0;
}
.grow-0 {
	@apply flex-grow-0;
}
.shrink {
	@apply flex-shrink;
}
.grow {
	@apply flex-grow;
}

/* ---------
DROPDOWN
--------- */
.r4-dropdown {
	@apply hidden absolute border border-gray-100 rounded bg-white;
	box-shadow: var(--shadow-elevation-low);
	min-width: 110px;
}
.r4-dropdown-act {
	@apply block;
}
.r4-dropdown .btn-list {
	@apply block my-1;
}
.r4-dropdown .btn-list a,
.r4-dropdown .btn-list button {
	@apply block my-0;
}

/* ---------
eRecept
--------- */

.erecept-table th,
.erecept-table-detail th {
	@apply px-3 py-2 font-display leading-normal font-semibold text-labelsmall text-gray-800 text-left tracking-wider bg-gray-200;
}
.erecept-table-detail tbody tr,
.erecept-table tbody tr {
	@apply bg-white;
}

.erecept-table tbody tr.erecept-table-tr-hover,
.erecept-table-detail tr.erecept-table-tr-hover {
	@apply cursor-default transition bg-gray-100;
}

.erecept-table-detail td,
.erecept-table td {
	@apply px-3 py-2 font-roboto leading-none font-medium text-label text-black text-left tracking-wider tabular-nums border-b border-gray-100;
}
.erecept-table-detail .item-last,
.erecept-table .item-last {
	@apply text-right;
}
.erecept-table-detail td.item-last,
.erecept-table td.item-last {
	@apply font-semibold text-cPurple-700;
}

/* This two classes needed for eRecept map moved to MAM webapp */
.erecept-table-margin {
	@apply max-w-4xl;
}

.erecept-region-map-margin {
	@apply w-8/12;
}

/* ---------
BTNS
--------- */
.btn-default {
	@apply inline-block my-1 px-2.5 py-1.5 rounded-sm md:text-label text-labelsmall font-display font-semibold text-cPurple-600 bg-cPurple-600 bg-opacity-20 tracking-wide transition transition-all hover:text-white hover:bg-opacity-100;
}
.btn-white-second,
.btn-white-second-small {
	@apply inline-block my-1 px-2.5 py-1.5 rounded-sm md:text-label text-labelsmall font-display font-semibold text-cPurple-800 bg-white tracking-wide transition transition-all hover:text-white hover:bg-cPurple-800;
}
.btn-white-second-small {
	@apply px-1 py-1 rounded-sm bg-cPurple-800 bg-opacity-10 whitespace-nowrap;
	font-size: 9px;
}
